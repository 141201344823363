export const GRAPHQL_ENDPOINT = "graphql";
export const SERVICE_STATUS_ENDPOINT = "ENTRYPOINT_SERVICE_STATUS_ENDPOINT";

export const SERVER_SETTINGS = {
  backendHost: "dev.sg.next.api.msgops.com",
  backendSsl: "ENTRYPOINT_TEMPLATE_BACKEND_SSL",
  clientId: "ba89dbbe2e1ec0bd7e11b102bd8298bd2ccd8318232a55c7",
  clientSecret: "a8b6881952de9dd987fe1ec97714d67bfd3c79eefb93e493",
  sleekplanProductId: "ENTRYPOINT_SLEEKPLAN_PRODUCT_ID",
  vapidPublicApplicationServerKey:
    "BHJamlhcFyXF6bVBCc2y6BNfEAgVfa8Z7nv0XGpPuXNfW7jJaGooY-KiOBI52PnTZO9ZkkfFczjWhSh2DfWSBiU",
};

export const MESSAGE_EDITOR_DEFAULT_CHAR_LIMIT = 4096;

export const CONVERSATION_EVENTS_PAGINATION_LIMIT = 50;
export const DEFAULT_PAGINATION_LIMIT = 30;
export const MAX_PAGINATION_LIMIT = 50;
export const MIN_PAGINATION_LIMIT = 10;
export const MIN_SEARCHTERM_LENGTH = 3;
export const MAX_TAGS_DISPLAYED = 4;
export const MAX_REPORTING_DATE_RANGE_DAYS_LIMIT = 60;

export const DEFAULT_TABLE_PAGINATION_STATE = {
  rowsPerPage: 25,
  selectedPage: 1,
};

// eslint-disable-next-line prettier/prettier
export const SENTRY_DSN = "https://59678dad98314739aac5c9fc7cd68d44@o548169.ingest.sentry.io/5670963";

export const GTM_ID = "ENTRYPOINT_GTM_ID";
